import React from "react"
import Layout from "react"
import Header from "../components/header/Header"
import HeaderMinimal from "../components/header/HeaderMinimal"
import MainMenu from "../components/header/Menu"
import HomeBody from "../components/home/HomeBody"
import Footer from "../components/footer/Footer"

import "../styles/global.css"
import "../styles/menu.css"
import Calendar from "../components/home/Calendar"

function MyApp () {

	return (
			<div className="site body">
				<Header />
				<MainMenu home="true"/>
				
				<main className="site-content">
					<HomeBody />
					<Calendar />
				</main>
				<Footer />
			</div>
	)
}

export default MyApp 